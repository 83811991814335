import React from 'react';
import { format } from 'date-fns'

function IncidentReport({report}) {
  return (
    <div className="report-wrap">
      <div className="report-date">{format(new Date(report.date_and_time), 'ccc, d MMMM yyy - h:mm aaa') }</div>
      <div className="report-title">{report.title }</div>
      <div className="report-status">Status: <span>{report.Status}</span></div>
      <div
        className="report-description"
        dangerouslySetInnerHTML={{ __html: report.description }}
      ></div>
    </div>
  );
}

export default IncidentReport;