import React, { useState, useEffect } from "react"
import { Link } from "gatsby"

import Seo from "../components/seo"
import StatusItem from "../components/statusItem"
import TswToolBoxLogo from "../assets/icons/TswToolBoxLogo.jsx"
import EmailSubscribe from "../components/EmailSubscribe"
import IncidentReport from "../components/IncidentReport"

const Status = () => {
  let ui = ""
  let b2b = ""
  let notifications = ""
  const orangeMessage = "There is currently an issue with some TSW services"

  // Client-side Runtime Data Fetching
  const [reports, setReports] = useState(null)
  const [statusInfo, setStatusInfo] = useState(null)
  const [systemInfo, setSystemInfo] = useState({
    systemStatus: null,
    systemMessage: null,
  })
  useEffect(() => {
    // get all incident reports
    fetch(
      `${process.env.GATSBY_STRAPI_API_URL}/incident-reports?_sort=published_at:DESC`
    )
      .then(response => response.json()) // parse JSON from request
      .then(resultData => {
        setReports(resultData)
      })

    // get Status page info
    fetch(`${process.env.GATSBY_STRAPI_API_URL}/status`)
      .then(response => response.json()) // parse JSON from request
      .then(resultData => {
        setStatusInfo(resultData)

        if (resultData) {
          ui = resultData.ui
          b2b = resultData.b2b
          notifications = resultData.notifications

          if (
            ui === "Operational" &&
            b2b === "Operational" &&
            notifications === "Operational"
          ) {
            setSystemInfo({
              systemStatus: "Operational",
              systemMessage: "All systems operational",
            })
          } else if (
            ui === "Some_Problems" ||
            b2b === "Some_Problems" ||
            notifications === "Some_Problems"
          ) {
            setSystemInfo({
              systemStatus: "Some_Problems",
              systemMessage: orangeMessage,
            })
          } else if (
            ui === "Down" ||
            b2b === "Down" ||
            notifications === "Down"
          ) {
            setSystemInfo({
              systemStatus: "Some_Problems",
              systemMessage: orangeMessage,
            })
          }

          if (ui === "Down" && b2b === "Down" && notifications === "Down") {
            setSystemInfo({
              systemStatus: "Down",
              systemMessage: "TSW unplanned outage",
            })
          }
        }
      })
  }, [])

  return (
    <React.Fragment>
      {statusInfo && (
        <div className="status-page">
          <div className="logo-wrap">
            <Link to="/">
              <TswToolBoxLogo />
            </Link>
          </div>
          <div className="main-col-one">
            <Seo title="Status" />
            <h1 className="status-title">Status</h1>

            <div className="system-wrap">
              <div className="system-status">
                {systemInfo.systemStatus && (
                  <StatusItem
                    statusMessage={systemInfo.systemMessage}
                    statusItem={systemInfo.systemStatus}
                  ></StatusItem>
                )}
              </div>

              <div className="detail-wrap">
                {statusInfo.ui && (
                  <StatusItem
                    statusMessage="UI"
                    statusItem={statusInfo.ui}
                  ></StatusItem>
                )}
                {statusInfo.b2b && (
                  <StatusItem
                    statusMessage="B2B"
                    statusItem={statusInfo.b2b}
                  ></StatusItem>
                )}
                {statusInfo.notifications && (
                  <StatusItem
                    statusMessage="Notifications"
                    statusItem={statusInfo.notifications}
                  ></StatusItem>
                )}
              </div>
            </div>

            <div className="report-block active-reports">
              <div className="report-block-title">Active Incidents</div>
              {reports &&
                reports.map((report, key) => {
                  console.log(report)
                  if (report.currently_active === true) {
                    return <IncidentReport report={report} key={key} />
                  }
                  return null
                })}
            </div>

            <EmailSubscribe
              title="Subscribe to status updates"
              target=""
              id="status-subscribe"
              statusSubscriber={true}
              changelog={false}
              status={true}
            />

            <div className="help-block">
              <div className="nh-title">Need Help</div>
              {statusInfo.Update_Report && (
                <div
                  className=""
                  dangerouslySetInnerHTML={{ __html: statusInfo.Update_Report }}
                ></div>
              )}
            </div>

            <div className="report-block archived-reports">
              <div className="report-block-title">Past Incidents</div>
              {reports &&
                reports.map((report, key) => {
                  if (report.currently_active === false) {
                    return <IncidentReport report={report} key={key} />
                  }
                  return null
                })}
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  )
}

export default Status
